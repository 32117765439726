import React, { Component } from "react";

import AccordionSection from "./accordionsection";

class Accordion extends Component {


  static defaultProps = {
    allowMultipleOpen: false,
    disabled: false
  };

  constructor(props) {
    super(props);

    const openSections = {};
    
    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });

    this.state = { openSections };
  }

  onClick = label => {
    const { props: { allowMultipleOpen, disabled }, state: { openSections } } = this;

    if(disabled) {
    
      return true;
    }
    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen
        }
      });
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen
        }
      });
    }

    var dataLayerConfig = {
      'event': 'accordeon',
      'eventCategory': 'Accordeon',
      'eventAction': label,  // e.g.: Title des Anchors
      'eventLabel' : (isOpen) ? 'Collapse': 'Expand' 
    };
    // console.log("datalayer push: ",dataLayerConfig);
    window.dataLayer.push(dataLayerConfig);
  };

  render() {
    const { 
      onClick,
      props: { children , className},
      state: { openSections },
    } = this;


    return (
      <div className={`accordion ${className}`}>
        {children.map((child,index) => (
          <AccordionSection
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            sublabel={child.props.sublabel || false}
            titleimage={child.props.titleimage || false}
            onClick={onClick}
            key={index}
            hasContent={child.props.children? true:false}
            id={child.props.id || ""}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </div>
    );
  }
}

export default Accordion;