import React, { Component } from "react";

class AccordionSection extends Component {
  onClick = () => {
    if (this.props.hasContent) {
      this.props.onClick(this.props.label);
    }
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, sublabel, titleimage, hasContent,id },
    } = this;
    return (
      <div
        {...(id ? { id: id } : {})}
        className={`accordion__section ${isOpen ? "is-open" : "is-closed"} ${
          hasContent ? "" : "is-empty"
        }`}
      >
        <div
          className={`accordion__title ${
            titleimage ? "accordion__title--titleImage" : ""
          }`}
          onClick={onClick}
          onKeyDown={onClick}
          role="button"
          tabIndex={0}
        >
          {titleimage && (
            <div className="accordion__titleimage">
              <img src={titleimage} alt={label} />
            </div>
          )}
          {sublabel ? (
            <span className="accordion__withsublabel">
              {label}
              <span>{sublabel}</span>
            </span>
          ) : (
            <span>{label}</span>
          )}
          {hasContent && (
            <svg
              className="accordion__arrow"
              enableBackground="new 0 0 50 50"
              height="50px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 50 50"
              width="50px"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect fill="none" height="50" width="50" />
              <polygon points="15,2.75 12.914,4.836 33.078,25 12.914,45.164 15,47.25 37.25,25 " />
            </svg>
          )}
        </div>

        <div className="accordion__content">
          <div className="accordion__content-inner">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default AccordionSection;
